import { Breadcrumb, DatePicker, Input, Modal, Table, Tag, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL, DEPLOYED_URL, USER } from '../../CONSTANTS'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'


const Accounts = () => {
    const { RangePicker } = DatePicker;
    const [accounts, setAccounts] = useState([]);
    const [currentUserData, setCurrentUserData] = useState();
    const [loading, setLoading] = useState(false);
    const bredCrumbItems = [
        {
            title: "API & Services"
        },
        {
            title: "ACCOUNT API > ACCOUNTS",
            href: `${DEPLOYED_URL}/accounts`,
            className: 'danger'
        }
    ]

    const accountsColumn = [
        {
            title: "Name",
            dataIndex: "username"
        },
        
        {
            title: "Status",
            dataIndex: "isActive",
            render: (text) => text === true ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,
            filters: [
                { text: 'Active', value: true },
                { text: 'Deleted', value: false }
            ],
            onFilter: (value, record) => record.isActive === value,
        },
        {
            title: "User Type",
            dataIndex: "accountUserType",
            render: (text) => text || '-'
        },
        {
            title: "Created",
            dataIndex: "createdOn",
           // render: (text) => moment(text).format('DD-MM-YYYY hh:mm:ss')
           render: (text) => moment(text).format('MMMM Do YYYY, h:mm:ss a'),
            sorter: (a, b) => {
            const dateA = moment(a.createdOn);
            const dateB = moment(b.createdOn);
            return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
          },
        },
        {
            title: "Wallet Address",
            dataIndex: "address"
        },

    ];

  /*  if (accounts.some(item => 'accountUserType' in item)) {
        accountsColumn.push({
            title: "User Type",
            dataIndex: "accountUserType"
        });
    }

    accountsColumn.push({
        title: "Created",
        dataIndex: "createdOn",
       // render: (text) => moment(text).format('DD-MM-YYYY hh:mm:ss')
       render: (text) => moment(text).format('MMMM Do YYYY, h:mm:ss a'),
        sorter: (a, b) => {
        const dateA = moment(a.createdOn);
        const dateB = moment(b.createdOn);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    });
    */

    const [createAccountModal, setCreateAccountModal] = useState({
        show: false,
        name: ""
    })
    const createAccount = async () => {
        setLoading(true)
        setCreateAccountModal(prev => ({
            ...prev,
            show: false
        }))

        const currentUserToken = await Auth.currentSession();
        axios.post(`${BASE_URL}/api/account`, {
            userId: currentUserData.userInfo._id,
            username: createAccountModal.name
        }, {
            headers: {
                cognitotoken: currentUserToken.accessToken.jwtToken
            }
        })
            .then(res => {
                setLoading(false)
                message.success("Account Created Successfully");
                getAllAccounts()
                setCreateAccountModal({
                    show: false,
                    name: ''
                })
            })
            .catch(err => {
                setLoading(false)
                message.error(err.response.data.message)
            })

    }
    // const getAllAccounts = async () => {
    //     setLoading(true)
    //     const currentUser = await Auth.currentAuthenticatedUser();
    //     const email = currentUser.attributes.email;
    //     const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;




    // }

    const getAllAccounts = async () => {
        setLoading(true)
        const currentUser = await Auth.currentAuthenticatedUser();
        const email = currentUser.attributes.email;
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
        await axios.get(`${BASE_URL}/api/user/email/${email}`, {
            headers: {
                cognitotoken: jwtToken
            }
        })
            .then(res => {
                setCurrentUserData(res.data.data)
                axios.post(`${BASE_URL}/api/account/all`, {}, {
                    headers: {
                        cognitotoken: jwtToken
                    }
                }).then(res => {
                    setLoading(false)
                    setAccounts(res.data.data);
                    console.log(res.data.data, "ACCOUNT RESPONSE")
                }).catch(err => {
                    setLoading(false)
                    setAccounts([]);
                    message.error(err.response.data.message)
                })
            })

    }
    useEffect(() => {
        getAllAccounts();
    }, [])

    const AddAccountFooter = () => {
        return (
            <div className='d-flex'>
                <button className='btn btn-primary me-3' onClick={createAccount}>Create</button>
                <button className='btn btn-secondary' onClick={() => setCreateAccountModal(prev => ({ ...prev, show: false }))}>Cancel</button>
            </div>
        )
    }

    return (
        <div className='p-3 pt-1'>
            <div className='py-2 bg-white px-2 text-dark'>
                <Breadcrumb separator=">" items={bredCrumbItems} />
                <div className='mt-2'>
                    <p className='h5 p-0 m-0 text-dark mb-2'>ACCOUNTS</p>
                    <p className='text-secondary'>Query and create EOA accounts using Account API.</p>
                </div>
            </div>
            <div className='mt-3'>
                <div className='bg-white d-flex justify-content-between w-100 mb-2 pt-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <div className='d-flex w-100 ms-2'>
                            <Input className='w-75' prefix={<AiOutlineSearch />} placeholder='Search' />
                            <RangePicker allowClear={false} className='date-picker ms-2 w-100' />
                            <BiRefresh style={{ fontSize: '40px' }} className='ms-2 cp p-0' />
                        </div>
                    </div>
                    {USER?.clusterInfo?.name !== 'avs' && <button className='btn btn-primary mb-3' onClick={() => setCreateAccountModal(prev => ({ ...prev, show: true }))}>+ Create Account</button>}
                </div>
                <Table pagination={{ pageSize: '6' }} loading={loading} showHeader={accounts.length} columns={accountsColumn} dataSource={accounts} />
            </div>
            <Modal footer={<AddAccountFooter />} title="Create Account" open={createAccountModal.show} onCancel={() => setCreateAccountModal(prev => ({ ...prev, show: false }))}>
                <Input placeholder='Enter Account Name' className='my-2' value={createAccountModal.name} onChange={e => setCreateAccountModal(prev => ({ ...prev, name: e.target.value }))} />
            </Modal>

        </div>

    )
}

export default Accounts