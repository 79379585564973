import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function PrivateRoute({ children }) {
  const [hasSession, setHasSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function checkSession() {
      try {
        await Auth.currentSession();
        setHasSession(true);
      } catch (error) {
        console.log('No active user session.');
      }
      setIsLoading(false);
    }

    checkSession();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return hasSession ? children : <Navigate to='/login' />
}

export default PrivateRoute;
