import Layout, { Content, Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sider from './components/sider/Sider'
import DeployedContracts from './components/contracts/deployedContracts/DeployedContracts';
import Login from './components/login/Login';
import Transactions from './components/contracts/transactions/Transactions';
import Events from './components/contracts/events/Events';
import Avatar from 'react-avatar';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './aws-exports';
import { Dropdown, Menu } from 'antd';
import PrivateRoute from './PrivateRoute';
import Grafana from './components/grafana/Grafana';
import Authentication from './components/security/authentication/Authentication';
import Footer from './components/footer/Footer';
import Swagger from './components/swagger/Swagger';
import { setLoggedInUser, setUser, USER } from './CONSTANTS';
import Users from './components/users/Users';
import Accounts from './components/account/Account';
import Explorer from './components/explorer/Explorer';

const Routers = () => {
  Amplify.configure(awsConfig);
  const location = useLocation();
  const isLogin = location.pathname === '/login';
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [authendicatedUser, setAuthendicatedUser] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    attributes: {
      name: ""
    }
  })

  async function authendicated() {
    try {
      await Auth.currentSession();
      setIsLoading(false)
      setAuthendicatedUser(true)
    } catch (error) {
      setIsLoading(false)
      setAuthendicatedUser(false)
    }

  }

  useEffect(() => {
    if (localStorage.hasOwnProperty('user-console-user-id')) {
      setLoggedInUser(localStorage.getItem('user-console-user-id'));
      setUser(JSON.parse(localStorage.getItem('user-console-user')))
    }
  }, [localStorage.length])

  const userMenu = () => {
    return (
      <Menu className='mt-2 cp'>
        <Menu.Item onClick={() => {
          localStorage.removeItem("baas-console-user")
          Auth.signOut();
          navigate("/login")
        }}>Log Out</Menu.Item>
      </Menu>
    )
  }
  useEffect(() => {
    authendicated()
  })

  useEffect(() => {
    (async () => {
      const user = await JSON.parse(localStorage.getItem("user-console-user"));
      setCurrentUser(user?.userInfo)
    })()
  }, [location.pathname])

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      {!isLogin && authendicatedUser && <Sider />}
      <Layout className="site-layout">

        {!isLogin && authendicatedUser && <Header
          className='p-0 header'
        >
          <Dropdown overlay={userMenu()} className='d-flex float-end me-3 mt-3'>
            <div className='d-flex align-items-center'>
              <Avatar name={currentUser.name.charAt(0)} size='50' textSizeRatio={2} round className='user-avatar me-2' />
              <div className='d-flex flex-column'>
                <p className='m-0 text-black h6'>{currentUser.name}</p>
                <p className='fw-semibold m-0 h6' style={{ color:'#3574B3'}}>{currentUser.isAdmin?'Admin':'User'}</p>
               </div>
            </div>
          </Dropdown>
        </Header>}
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          <Routes>
            <Route exact path='/' element={authendicatedUser ? <Navigate to={'/deployedContracts'} /> : <Navigate to={'/login'} />}></Route>
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/accounts' element={<PrivateRoute><Accounts /></PrivateRoute>} />
            <Route exact path='/deployedContracts' element={<PrivateRoute><DeployedContracts /></PrivateRoute>} />
            <Route exact path='/allTransactions' element={<PrivateRoute><Transactions /></PrivateRoute>} />
            <Route exact path='/authentication' element={<PrivateRoute><Authentication header={'security'} /></PrivateRoute>} />
            <Route exact path='/allEvents' element={<PrivateRoute><Events /></PrivateRoute>} />
            <Route exact path='/grafana' element={<PrivateRoute><Grafana /></PrivateRoute>} />
            {USER?.clusterInfo?.name !== 'avs' && <Route exact path='/swagger' element={<PrivateRoute><Swagger /></PrivateRoute>} />}
            <Route exact path='/users' element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route exact path='/block-explorer' element={<PrivateRoute><Explorer /></PrivateRoute>} />
            <Route exact path='*' element={authendicatedUser ? <Navigate to={'/deployedContracts'} /> : <Navigate to={'/login'} />}/>
          </Routes>
          {isLogin ? <Footer /> : null}
        </Content>
      </Layout>
    </Layout>

  )
}

export default Routers;