import { Breadcrumb, Tabs } from 'antd'
import ExplorerSearch from './ExplorerSearch';
import './Explorer.scss'
import Nodes from './Nodes';

const Explorer = () => {
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: 'Nodes',
            children: <Nodes />,
        },
        {
            key: '2',
            label: 'Explorer',
            children: <ExplorerSearch />,
        }
    ];
    const bredCrumbItems = [
        {
            title: "Network Monitoring"
        },
        {
            title: "FLEXBLOK Explorer",
            href: `/block-explorer`
        }
    ]
    return (
        <div className='p-3 pt-1 explorer'>
            <div className='py-2 bg-white px-2 text-dark'>
                <Breadcrumb separator=">" items={bredCrumbItems} />
                <div className='mt-2'>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} centered />
                </div>
            </div>
        </div>
    )
}

export default Explorer