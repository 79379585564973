import moment from "moment";

export const dateFormat=(text)=>{
    const date = moment(text).format('YYYY-MM-DD')
    const time = moment(text).format('HH:mm:ss')
    return `${date}  ${time}`;
}

export const timeFormat = (text) => {
    const timestampHex = text; // Example hexadecimal timestamp
    const timestamp = parseInt(timestampHex, 16);
    const currentTime = Math.floor(Date.now() / 1000);
    const secondsAgo = currentTime - timestamp;
    return secondsAgo;
}

export const hexaConversion=(text)=>{
    const hex = text;
    const decimal = parseInt(hex, 16);
    return decimal;

}