const DEPOLYED_HOST = new URL(window.location).hostname;
export const BASE_URL = `https://${DEPOLYED_HOST}/customer-api`;
export const DEPLOYED_URL = `https://${DEPOLYED_HOST}`;
export const FLEXBLOK_BLOCK_EXPLORER_BASE_URL = `https://${DEPOLYED_HOST}/explorer/api`;
export const RPC_URL = process.env.REACT_APP_RPC_URL;


//LOGGEDIN USER DETAILS
export const LOGGEDIN_USER = {
    userId: ''
}
export let USER = '';
export const setUser = (userData) => {
    USER = userData
}
export const setLoggedInUser = (newID) => {
    LOGGEDIN_USER.userId = newID
}

export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID;
// export const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
// export const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;