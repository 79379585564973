import { Select } from 'antd';
import { BASE_URL } from '../../CONSTANTS';
import './Grafana.scss'
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

function GrafanaDashboard() {
    //   useEffect(() => {
    //     const iframe = document.getElementById('grafana-iframe');
    //     const iframeDocument = iframe.contentDocument;
    //     const style = iframeDocument.createElement('style');
    //     style.textContent = `
    //       body {
    //         font-size: 100px;
    //       }
    //     `;
    //     iframeDocument.head.appendChild(style);
    //   }, []);

    const timeOptions = [
        {
            label: 'Last 24 hrs',
            value: 24,
            timeStamp: () => {
                return { endDate: moment().valueOf(), startDate: moment().subtract(24, 'hours').valueOf() }
            }
        },
        {
            label: 'Last 2 days',
            value: '2 Days',
            timeStamp: () => {
                return { endDate: moment().subtract(1, 'day').endOf('day').valueOf(), startDate: moment().subtract(2, 'days').startOf('day').valueOf() }
            }
        }
    ]
    useEffect(() => {
        const temp = { endDate: moment().valueOf(), startDate: moment().subtract(24, 'hours').valueOf() }
        setSelectedTimeStamp(temp)
    }, [])

    const [selectedTimeStamp, setSelectedTimeStamp] = useState({
        startDate: '',
        endDate: ''
    })
    const selectedTimeRange = (val, h) => {
        setSelectedTimeStamp(h.timeStamp())
    }
    return (
        <div className='grafana'>
            <Select onChange={selectedTimeRange} defaultValue={24} style={{ width: '200px' }} options={timeOptions} className='my-3 me-2 float-end'></Select>
            <iframe
                style={{ fontSize: "10px" }}
                title="grafana-iframe"
                src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=10`}
                width="100%"
                height="500"
                frameBorder="0"
            />
            {/* <iframe title="fast sync progress"
                src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=15`}
                width="100%" height="500"
                frameborder="0"></iframe> */}

            <div className='d-flex'>
                <iframe className='w-50' height="500" title="3" src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=12`} width="450" frameborder="0"></iframe>
                <iframe className='w-50' height="500" title="4" src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=13`} width="450" frameborder="0"></iframe>
            </div>
            <iframe title="cpu" src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=6`} width="100%" height="500" frameborder="0"></iframe>
            <div className='d-flex'>
                <iframe title='GC time' src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=8`} width="50%" height="500" frameborder="0"></iframe>
                <iframe title='memory used' src={`${process.env.REACT_APP_GRAFANA_URL}?orgId=1&refresh=10s&from=${selectedTimeStamp.startDate}&to=${selectedTimeStamp.endDate}&theme=light&panelId=4`} width="50%" height="500" frameborder="0"></iframe>
            </div>
        </div>

    );
}

export default GrafanaDashboard;
