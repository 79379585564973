import status from "../assets/img/status.svg";
import blocks from "../assets/img/blocks.svg";
import peers from "../assets/img/peers.svg";
import queued from "../assets/img/queued.svg";
export const NodeData = [
    {
        label: 'Status',
        icon: status
    },
    {
        label: 'Blocks',
        icon: blocks
    },
    {
        label: 'Peers',
        icon: peers
    },
    {
        label: 'Queued',
        icon: queued
    },
]

export const clientData = [
    {
        label: 'Client'
    },
    {
        label: 'Node ID'
    },
    {
        label: 'Node Name'
    },
    {
        label: 'Enode'
    },
    {
        label: 'RPC Url'
    },
    {
        label: 'IP Address'
    }
]

export const peerColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'E-Node',
        dataIndex: 'enode',
        key: 'enode',
    },
    {
        title: 'Local Address',
        dataIndex: 'localAddress',
        key: 'localAddress',
    },
    {
        title: 'Remote-Address',
        dataIndex: 'remoteAddress',
        key: 'remoteAddress',
    }
]