import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css";
import './Swagger.scss'
import { DEPLOYED_URL, USER } from "../../CONSTANTS";
import { Breadcrumb } from "antd";

const Swagger = ()=>{
  const bredCrumbItems = [
    {
        title: "API & Services"
    },
    {
        title: "API EXPLORER",
        href: `${DEPLOYED_URL}/swagger`
    }
]
  return (
    <div className="swagger p-3 pt-1">
         <div className='py-2 bg-white px-2 text-dark'>
                <Breadcrumb separator=">" items={bredCrumbItems} />
                {/* <div className='mt-2'>
                    <p className='h5 p-0 m-0 text-dark mb-2 text-uppercase'>API EXPLORER</p>
                    <p className='text-secondary'>You can manage the API keys of your FLEXBLOK account</p>
                </div> */}
            </div>
      <SwaggerUI  docExpansion="false" className='h-100'  url={`${DEPLOYED_URL}/besu-api/swagger.json`} />
    </div>
  );
}

export default Swagger;
