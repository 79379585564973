import { Breadcrumb, Input, Modal, Table, message,DatePicker  } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL, DEPLOYED_URL } from '../../../CONSTANTS'
import { Auth } from 'aws-amplify'
import { DeleteOutlined } from '@ant-design/icons'
import { AiOutlineSearch } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'

const Authentication = () => {

    const {RangePicker} = DatePicker;
    const [APIKeyData, setAPIKeyData] = useState([]);
    const [currentUserData, setCurrentUserData] = useState();
    const [loading, setLoading] = useState(false);
    const bredCrumbItems = [
        {
            title: "API & Services"
        },
        {
            title: "SECURITY > AUTHENTICATION",
            href: `${DEPLOYED_URL}/authentication`
        }
    ]

    const APIKeyColumns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "API Key",
            dataIndex: "apiKey"
        },
        {
            title: "User ID",
            dataIndex: "userId"
        },
        {
            title: "Created",
            dataIndex: "createdOn"
        },
        {
            title: "Action",
            dataIndex: '_id',
            render: (text) => {
                return (<span className='cp' onClick={() => setShowDeleteAPIKeyModal({
                    show: true,
                    apiKeyId: text
                })}>
                    <DeleteOutlined />
                </span>)
            }
        }
    ]
    const [apiKeyModal, setapiKeyModal] = useState({
        show: false,
        name: ""
    })
    const generateAPIKey = async () => {
        setLoading(true)
        setapiKeyModal(prev => ({
            ...prev,
            show: false
        }))

        const currentUserToken = await Auth.currentSession();
        axios.post(`${BASE_URL}/api/key/generate`, {
            userId: currentUserData.userInfo._id,
            name: apiKeyModal.name
        }, {
            headers: {
                cognitotoken: currentUserToken.accessToken.jwtToken
            }
        })
            .then(res => {
                setLoading(false)
                message.success("API Key Genrated Successfully");
                getAllAPIKeys()
                setapiKeyModal({
                    show: false,
                    name: ''
                })
            })
            .catch(err => {
                setLoading(false)
                message.error(err.response.data.message)
            })

    }
    const getAllAPIKeys = async () => {
        setLoading(true)
        const currentUser = await Auth.currentAuthenticatedUser();
        const email = currentUser.attributes.email;
        const jwtToken = currentUser.signInUserSession.accessToken.jwtToken;
        await axios.get(`${BASE_URL}/api/user/email/${email}`,{
            headers: {
                cognitotoken: jwtToken
            }
        })
            .then(res => {
                setCurrentUserData(res.data.data)
                axios.get(`${BASE_URL}/api/key/${res.data.data.userInfo._id}`, {
                    headers: {
                        cognitotoken: jwtToken
                    }
                }).then(res => {
                    setLoading(false)
                    const temp = [];
                    temp.push(res.data.data)
                    setAPIKeyData(temp)
                }).catch(err => {
                    setLoading(false)
                    setAPIKeyData([]);
                    message.error(err.response.data.message)
                })
            })

    }
    useEffect(() => {
        getAllAPIKeys();
    }, [])
    const APIKeyModalFooter = () => {
        return (
            <div className='d-flex'>
                <button className='btn btn-primary me-3' onClick={generateAPIKey}>Generate</button>
                <button className='btn btn-secondary' onClick={() => setapiKeyModal(prev => ({ ...prev, show: false }))}>Cancel</button>
            </div>
        )
    }
    const [showDeleteAPIKeyModal, setShowDeleteAPIKeyModal] = useState(false);
    const deleteAPIKey = async (apiKeyId) => {
        setShowDeleteAPIKeyModal(prev => ({
            ...prev,
            show: false,
        }))
        const currentUserToken = await Auth.currentSession();
        axios.delete(`${BASE_URL}/api/key`, {
            data: {
                userId: currentUserData.userInfo._id,
                apiKeyId: showDeleteAPIKeyModal.apiKeyId,
            },
            headers: {
                cognitotoken: currentUserToken.accessToken.jwtToken
            }
        })
            .then(res => {
                message.success("API Key Deleted Successfully");
                setShowDeleteAPIKeyModal({
                    show: false,
                    apiKeyId: ''
                })
                getAllAPIKeys()
            })
            .catch(err => {
                message.error(err.response.data.message)
            })
    }

    const DeleteAPIKeyFooter = () => {
        return (
            <div className='d-flex'>
                <button className='btn btn-primary me-3' onClick={deleteAPIKey}>Delete</button>
                <button className='btn btn-secondary' onClick={() => setShowDeleteAPIKeyModal(prev => ({ ...prev, show: false }))}>Cancel</button>
            </div>
        )
    }
    return (
        <div className='p-3 pt-1'>
            <div className='py-2 bg-white px-2 text-dark'>
                <Breadcrumb separator=">" items={bredCrumbItems} />
                <div className='mt-2'>
                    <p className='h5 p-0 m-0 text-dark mb-2 text-uppercase'>Authentication</p>
                    <p className='text-secondary'>You can manage the API keys of your FLEXBLOK account</p>
                </div>
            </div>
            <div className='mt-2'>
                <div className='bg-white d-flex justify-content-between w-100 mb-2 pt-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <div className='d-flex w-100 ms-2'>
                            <Input className='w-75' prefix={<AiOutlineSearch />} placeholder='Search' />
                            <RangePicker allowClear={false} className='date-picker ms-2 w-100' />
                            <BiRefresh style={{ fontSize: '40px' }} className='ms-2 cp p-0' />
                        </div>
                    </div>
                    <button className='btn btn-primary mb-3' onClick={() => setapiKeyModal(prev => ({ ...prev, show: true }))}>+ Create API Key</button>
                </div>
                <Table loading={loading} showHeader={APIKeyData.length ? true : false} columns={APIKeyColumns} dataSource={APIKeyData} />
            </div>
            <Modal footer={<APIKeyModalFooter />} title="API Key Generation" open={apiKeyModal.show} onCancel={() => setapiKeyModal(prev => ({ ...prev, show: false }))}>
                <Input placeholder='Enter Name' className='my-2' value={apiKeyModal.name} onChange={e => setapiKeyModal(prev => ({ ...prev, name: e.target.value }))} />
            </Modal>
            <Modal footer={<DeleteAPIKeyFooter />} title="Delete API Key" open={showDeleteAPIKeyModal.show}>
                <div className='py-2'>Are you sure want to delete the API key?</div>
            </Modal>
        </div>

    )
}

export default Authentication