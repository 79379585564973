import { Breadcrumb, DatePicker, Input, Modal, Table, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Transactions.scss';
// import { BiRefresh } from 'react-icons/bi';
import { BiRefresh } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import { BASE_URL, DEPLOYED_URL, LOGGEDIN_USER } from '../../../CONSTANTS';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { dateFormat } from '../../../utils/genericFuntions';


const Events = () => {
  const [loading, setLoading] = useState([])
  const [modifiedData, setModifiedData] = useState([])
  const [filter, setFilter] = useState({
    search: "",
    startDate: "",
    endDate: "",
    userId:LOGGEDIN_USER.userId
  })
  const transactionsColumns = [
    {
      title: "Transaction Type",
      dataIndex: "transactionType"
    },
    {
      title: "Contract Name",
      dataIndex: "contractName"
    },
    {
      title: "Created",
      dataIndex: "createdOn",
      render: (text) => {
        return dateFormat(text);
      },
      sorter: (a, b) => {
        const dateA = moment(a.createdOn);
        const dateB = moment(b.createdOn);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      title: "Transaction  Hash",
      dataIndex: "transactionHash"
    }
  ]

  const getAllTransactions = async () => {
    const userDetails = await Auth.currentSession();
    setLoading(true)
    axios.post(`${BASE_URL}/api/contract/getAllTransactions`, {
      search: filter.search,
      startDate: filter.startDate.date,
      endDate: filter.endDate.date,
      userId:LOGGEDIN_USER.userId
    }, {
      headers: {
        cognitotoken: userDetails.accessToken.jwtToken
      }
    })
      .then(res => {
        setLoading(false)
        setModifiedData(res.data.data);

      }).catch(err => {
        message.error(err.response.data.message)
        setLoading(false);
      })
  }
  useEffect(() => {
    if (LOGGEDIN_USER.userId) {
      getAllTransactions();
    }
  }, [filter, LOGGEDIN_USER.userId])


  const bredCrumbItems = [
    {
      title: "API & Services"
    },
    {
      title: "TRANSACTION API > Contract Transactions",
      href: `${DEPLOYED_URL}/allTransactions`
    }
  ]

  const { RangePicker } = DatePicker;
  const [moreDetailsModal, setMoreDetailsModal] = useState({
    show: false,
    data: []
  });
  return (
    <div className='p-3 pt-1'>
    <div className='py-2 bg-white px-2 text-dark'>
      <Breadcrumb separator=">" items={bredCrumbItems} />
      <div className='mt-2'>
        <p className='h5 p-0 m-0 text-dark mb-2 text-uppercase'>Contract Transactions</p>
        <p className='text-secondary'>You can search for Contract Transactions.</p>
      </div>
    </div>
    <div className='bg-white d-flex justify-content-between w-100 mb-2 pt-3 mt-3'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex w-100 ms-2'>
            <Input value={filter.search} className='w-50' onChange={e => setFilter(prev => ({ ...prev, search: e.target.value }))} prefix={<AiOutlineSearch />} placeholder='Search' />
            <RangePicker allowClear={false} value={[filter.startDate.dateObj, filter.endDate.dateObj]} className='date-picker ms-2' onChange={(e, m) => { setFilter(prev => ({ ...prev, startDate: { date: m[0], dateObj: e[0] }, endDate: { date: m[1], dateObj: e[1] } })) }} />
            <BiRefresh style={{ fontSize: '35px' }} onClick={e => setFilter(prev => ({ search: "", startDate: { dateObj: "", date: "" }, endDate: { dateObj: "", date: "" } }))} className='ms-3 cp' />
          </div></div>
      </div>
      <Table onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            setMoreDetailsModal({
              show: true,
              data: record
            })
          }
        }
      }} loading={loading} showHeader={modifiedData.length ? true : false} columns={transactionsColumns} dataSource={modifiedData} pagination={{ pageSize: 6 }} />
      {<Modal open={moreDetailsModal.show} className='w-75' footer='' onCancel={() => setMoreDetailsModal({ show: false, data: [] })}>
        <table className='table'>
          <tbody>
            {Object.keys(moreDetailsModal.data).map(el => {
              return <tr>
                <td className='fw-bold'>{el}</td>
                <td style={{wordBreak:'break-word'}}>{JSON.stringify(moreDetailsModal.data[el])}</td>
              </tr>
            })}
          </tbody>
        </table>
      </Modal>}
    </div>
  )
}

export default Events;