import { Footer } from 'antd/es/layout/layout'
import './Footer.scss'
import infLogo from '../../assets/img/inf-logo.png'

const FooterComp = () => {
    return (
        <Footer className='footer d-flex align-items-center'>
            <img src={infLogo} alt="Logo" />
            <a href='https://www.innoflexion.com/terms-conditions/' target='_blank' rel="noreferrer" >Terms & Conditions</a>
            <span>© 2023 Innoflexion | All rights reserved.</span>
        </Footer>
    )
}

export default FooterComp